<template>
<v-container>
  
  <v-row class="text-center" align="center">
    
    <v-col align="center" style="padding:0 ;padding-top:5px;">
      <div id="container"></div>
    </v-col>
  </v-row>

  <v-row class="text-center">

    <v-col >
        <div id="answer" v-if='gameSetting'> 
          <span style="
          font-size: 26px; 
          color: #FFFFFF; 
          letter-spacing: 5px; 
          text-align: center;">{{guessedAnswer|upper}}</span><br>

        
          <span style="font-size: 24px"> {{gameMojis}} </span>
          <!-- <span> {{finalMojis}} </span> -->
          <!--
          <span v-if="won"> 📸 </span> 
          <span v-else> 📷 </span> 
          <span v-for="guess in ( guessLimit - wrongGuesses) " :key="guess+'-b'"> ⬛️ </span> 
          <span v-for="guess in (wrongGuesses) " :key="guess+'r'"> 🟥 </span> 
          -->
           <!-- {{wrongGuesses}}/{{guessLimit}} -->

        </div>    
    </v-col>
  </v-row>
  <v-row class="text-center">
    <v-col>
      
    <div id="buttons" >
      <span  v-for="letter in letters" :key="letter">
           
            
    <v-btn 
      elevation="1"
      @click="guess(letter)"
      v-if="letter != '1' && letter != '0' && letter != '2' "
      v-bind:style="{ margin :'2px', 'background-color': buttonColor(letter) }"
      :class="$mq"
      :min-width=0
      :width="keyWidth"
      :height="keyHeight"
      :disabled="finished"
      v-bind="keySize"
      
      >
     
          <!--<span v-if="letter === '1'">ENT</span>
          <span v-else-if="letter === '0'">DEL </span>
          <span v-else-if="letter === '2'">SPACE </span>
        -->
          <span v-bind:style="{'font-size': keyFontSize}">{{letter | upper}}</span>
        
    </v-btn>
      <br v-if="letter === 'p'" >
       <br v-if="letter === 'l'" >
       <br v-if="letter === '0'" >
    </span>


    </div>
    </v-col>
  </v-row>


</v-container>
</template>
<style>

</style>
<script>
import Konva from 'konva';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      letters: Array.from('qwertyuiopasdfghjkl1zxcvbnm02'),

      configStage: {
        container:"container",
        width: 374,
        height: 374,
      },
      configLayer:{

      },
      configPic: {
        image:null,
        width: 374,
        height: 374,
      },
      multiple: 1,
      strikes: 0,
      guessNo: 0,
      finished: false,
      gameSetting: null,
      guessLimit: 6,
      guessedLetters: [],
      finalGuessedLetters: [],
      shareImageBlob: null,
      firstLoad: true,
      pixelSize: 0,
      initialPixelSize: null,
      stage: null,
      pics:[
        {"id":4,
         "word":"sunflower",
         "src":"4.png"
        }
        ,
        {"id":5,
         "word":"lotus",
         "src":"5.png"
        },
        {"id":6,
         "word":"cat",
         "src":"6.png"
        },
        {"id":7,
         "word":"bulldog",
         "src":"7.png"
        },
        {"id":8,
         "word":"avocado",
         "src":"8.png"
        },
        {"id":9,
         "word":"pizza",
         "src":"9.png"
        },
        {"id":10,
         "word":"eggs",
         "src":"10.png"
        },
        {"id":11,
         "word":"pears",
         "src":"11.png"
        },
        {"id":12,
         "word":"palmtree",
         "src":"12.png"
        },
        {"id":13,
         "word":"cappuccino",
         "src":"13.png"
        }
        ]

    };
  },
  computed: {
    randomGame: function(){
      return this.pics[Math.floor(Math.random() * this.pics.length)]
    },
    imagePath:function (){
      return require( "@/assets/" + this.gameSetting.src )
    },
    guessedAnswer: function(){
      return this.gameSetting.word.replace(new RegExp(`[^${this.guessedLetters.join('')}]`, 'g'), '_');
    },
    wrongGuesses: function(){
      var $vm = this;
      return this.guessedLetters.filter(function(a){ return !$vm.letterInWord(a) }).length

    },
    done: function(){
      return Array.from( this.gameSetting.word ).every(item => this.guessedLetters.join('').includes(item)) 
    },
    lost: function(){
      return this.wrongGuesses == this.guessLimit
    },
    won: function(){
      return this.done && this.wrongGuesses < this.guessLimit
    },
    computedPixelSize: function(){
      return this.configPic.width * (  this.multiple ) 
    },
    keySize () {
      const size = {mobile:'x-small',tablet:'small',laptop:'large',desktop:'x-large'}[this.$mq];
      return size ? { [size]: true } : {}
    },
    keyWidth () {
      const w = {mobile:'24px',tablet:'28px',laptop:'50px',desktop:'64px'}[this.$mq];
      return w ? w : w['mobile']
    },
    keyMinWidth(){
      const size = {mobile:'x-small',tablet:'x-small',laptop:'large',desktop:'x-large'}[this.$mq];
      return size ? { [size]: true } : {}
    },
    keyFontSize(){
      const size = {mobile:'14pt',tablet:'16pt',laptop:'24pt',desktop:'24pt'}[this.$mq];
      return size ? size : size['mobile']
    },
    keyHeight(){
      const size = {mobile:'36px',tablet:'36px',laptop:'60px',desktop:'64px'}[this.$mq];
      return size ? size : size['mobile']
    },
    gameMojis(){
      //var $vm = this
      //var numGuessedMojis = //"⬛️".repeat(this.guessLimit - this.wrongGuesses) + 
                          //  "🟥".repeat( this.wrongGuesses)
      var count = this.wrongGuesses + "/" + this.guessLimit
      var mojisText = "💥".repeat(this.wrongGuesses)
      return mojisText + " " +count
    },
    finalMojis(){
            var $vm = this
      //var numGuessedMojis = "⬛️".repeat(this.guessLimit - this.wrongGuesses) + 
      //                       "🟥".repeat( this.wrongGuesses)

      var guessedMojis = this.finalGuessedLetters.map( 
                  function(letter){ 
                      return $vm.colormoji(letter) 
                  })

      var mojisText = guessedMojis.join('')
      var cursor = ( this.won || this.lost) ? '' : '💥';
      
      var blankSpace = (this.guessLimit - this.wrongGuesses ) ? "⬛️".repeat(this.guessLimit - 1- this.wrongGuesses ) : ""

      mojisText += cursor + blankSpace;
      var whichMojis = mojisText//numGuessedMojis

      var picFrame = '🖼';

      var cam = this.won ? '📸' : '📷' 
       if (this.lost) {
        cam = '💥'
       }

      var finalMojis =  picFrame + " " + whichMojis + " " + cam
      
      return finalMojis
    }
  },
  methods: {
     ...mapActions([
      'setMessage',
      'setShareFiles',
      'setShowDialog',
      'setSnackbar',
      'disableDialog',
      'setDialog'

      ]),
    message: function(){
      
      var finalTitle = "Eyedle " + this.gameSetting.id;
      var finalIcon = ( this.won ) ? '🖼' : '💥';
      var wonLost = ( this.won ) ? 'Solved' : 'Missed';
      var count = this.wrongGuesses + "/" + this.guessLimit

      //var finalState = wonLost + ". " + count;

      var finalString = finalTitle + "\n" + finalIcon + " " + wonLost + "\n" + count
      return finalString
    },
    colormoji(letter){
      var color = null;
      if ( this.guessedLetters.includes(letter) ){
        if ( this.letterInWord(letter) ){
          color = '🟩'
        }else{
          color = '⬛️'  //'🟥' //
        }
      }
      return color    
    },
    buttonColor(letter){
      var color = null;
      
      if ( this.guessedLetters.includes(letter) ){
        if ( this.letterInWord(letter) ){
          color = '#529E21'// '#00A86b' 
        }else{
          color = '#B33F40'
        }
      }
      return color     
    },
    letterInWord(letter){
      return this.gameSetting.word.indexOf(letter) >= 0
    },
    runFinished(msg, snackMsg, dialog){
      var $vm = this
      this.setMessage( msg )
      this.setSnackbar( { show:true, text: snackMsg } )
      this.setDialog( dialog )
      this.finished = true;
      

      setTimeout(function(){
        $vm.setShowDialog({show: true}) 
      }, 3000)

      setTimeout(function(){
        $vm.createDownload();
      }, 3000)
    },
    createDownload(){
      var $vm = this
      //var blob = this.shareImageBlob
      
      var squareSide = 250;

      var finalTitle = "Eyedle " + this.gameSetting.id;
      var finalIcon = ( this.won ) ? '🖼' : '💥';
      var wonLost = ( this.won ) ? 'Solved' : 'Missed';
      var count = this.wrongGuesses + "/" + this.guessLimit

      var finalState = wonLost + ". " + count;
      
      var xCenterSquare = ( ( $vm.stage.width() - squareSide ) /2 )
      var yCenterSquare = (( $vm.stage.height() - squareSide) / 2);
      var xCenter = ( $vm.stage.width() / 2) ;
      var yCenter = ( $vm.stage.height() / 2)
      
      var square = new Konva.Rect({
        x: xCenterSquare ,
        y: yCenterSquare,
        width: squareSide,
        height: squareSide,
        fill: 'rgba(75,77,81,0.76)',
      });

      var title = new Konva.Text({
        x: xCenter  ,
        y: yCenter - 50, // this needs to be calculated
        text: finalTitle,
        fontSize: 24,
        align: "center",
        verticalAlign: 'middle',
        fontFamily: 'Courier,sans-serif',
        fill: 'white'
      });

      var icon = new Konva.Text({
        x: xCenter ,
        y: yCenter - 20 ,  // this needs to be calculated
        text: finalIcon,
        fontSize: 24,
        align: "center",
        verticalAlign: 'middle',
        fontFamily: 'Courier,sans-serif',
        fill: 'white'
      });

      var state = new Konva.Text({
        x: xCenter ,
        y: yCenter + 25 ,  // this needs to be calculated
        text: finalState,
        fontSize: 24,
        align: "center",
        verticalAlign: 'middle',
        fontFamily: 'Courier,sans-serif',
        fill: 'white'
      });



      //shareImageDataUrl
       var imageURL = this.shareImageDataUrl
       var tempLayer = new Konva.Layer($vm.configLayer)

       Konva.Image.fromURL(imageURL, function(image){
        image.setAttr('width', $vm.configPic.width);
        image.setAttr('height', $vm.configPic.height);       
        image.cache();
        image.filters([Konva.Filters.Pixelate]);
        
        image.pixelSize( $vm.initialPixelSize );

        tempLayer.add(image);
        tempLayer.add(square);

        title.offsetX(title.width() / 2)
        
        tempLayer.add(title);
        icon.offsetX(icon.width() / 2)
        tempLayer.add(icon);
        
        state.offsetX(state.width() / 2) 
        tempLayer.add(state);


        var layerUrl = tempLayer.toDataURL()
         fetch(layerUrl)
            .then(res => res.blob())
            .then(blob => $vm.setShareFiles( [new File([blob], 'eyedle-'+ $vm.gameSetting.id+'.png', { type: blob.type })] ) )

      })

      //this.setShareFiles( [new File([blob], 'eyedle-'+ this.gameSetting.id+'.png', { type: blob.type })] )
    },
    guess(letter){
      var $vm = this
      var msg = ""
      var snackMsg = ""
      var dialog = {}
      if( this.guessedLetters.includes(letter) ){
           // console.log("Already Guessed", letter)
      }else{
        this.guessedLetters.push(letter)
        this.finalGuessedLetters.push(letter)
        this.guessNo += 1;
        
        //console.log("guessNo, wrongGuesses", this.guessNo, this.wrongGuesses)
        
        if(this.lost){
          msg = this.message()
          snackMsg = "😭 Oh No!!"
          dialog = {
              title: "Bummer...",
              text: "You didn't win this time. Refresh to play again.<br><br>I’m still looking for bugs. Let me know if you find any."
          }
          
          var wordLetters = this.gameSetting.word.split('')
          //this.finalGuessedLetters = this.guessedLetters

          wordLetters.forEach(function(m){
            setTimeout(function(){
                $vm.guessedLetters.push(m) 

              }, 2000) 

          })
          this.runFinished(msg, snackMsg, dialog)
        }

        if( this.letterInWord(letter)  ){
          //console.log("New Hit!")
          this.strikes += 2;
          this.multiple = this.multiple * 0.5;
          
          if (this.done  ){
            msg = this.message()
            snackMsg = "🎉 Looking Good!"
            dialog = {
              title: "Great eye!",
              text: " Congrats on winning this time. Refresh to play again.<br><br>I’m still looking for bugs. Let me know if you find any."
            }

            this.runFinished(msg, snackMsg, dialog)
          }    
        }else{
          //console.log("New Miss")
  
        }
      }

      this.drawPic();
    },
    drawPic(){
      
      const $vm = this;

      if(!$vm.stage ){
        //console.log('making new stage')
        $vm.stage = new Konva.Stage($vm.configStage)
      }
      if(!$vm.layer){
        $vm.layer = new Konva.Layer($vm.configLayer)
      }
      
      var old_image = $vm.stage.find('#pic')[0]

      const imageURL =   $vm.imagePath 

      

      Konva.Image.fromURL(imageURL, function(image){
        image.setAttr('width', $vm.configPic.width);
        image.setAttr('height', $vm.configPic.height);       
        image.cache();
        image.filters([Konva.Filters.Pixelate]);
        //var width = image.width() 
        
        if(!$vm.finished){
          //console.log("pixelsize:", $vm.computedPixelSize)
          image.pixelSize( $vm.computedPixelSize );
        }else{
          image.pixelSize( 0.5 )
        }
        
        image.id("pic");
        $vm.layer.add(image);
        $vm.stage.add($vm.layer);



        if(old_image){
          old_image.destroy();
        }

        if( $vm.firstLoad ){
          //console.log( "Let's make the sharable image")
          $vm.firstLoad = false;
          //var dataUrl = $vm.stage.toDataURL()
          
          $vm.initialPixelSize = $vm.configPic.width * (  $vm.multiple );
          //this.shareImageDataUrl = image.toDataURL()
          
          var dataUrl = image.toDataURL()
          $vm.shareImageDataUrl = dataUrl

            fetch(dataUrl)
            .then(res => res.blob())
            .then(blob => $vm.shareImageBlob = blob)
            //.then(blob => $vm.setShareFiles( [new File([blob], 'eyedle-'+ $vm.gameSetting.id+'.png', { type: blob.type })] ) )
          
        }




      })


    
    }
  },
  created() {
    
  },
  mounted() {
     this.$nextTick( () => {
        this.gameSetting = this.randomGame
        this.drawPic()
      })
  },
  filters: {
    upper: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.toUpperCase()
    }
}
};

</script>
